<template>
  <div>
    <a-form-model
      ref="courseForm"
      :model="courseForm"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 18 }"
    >
      <div v-for="(course, index) in courseForm.courses" :key="course.key">
        <a-form-model-item
          label="时段标题"
          :prop="'courses.' + index + '.subTitle'"
          :rules="{
            message: '请输入时段标题',
            trigger: 'blur',
          }"
        >
          <a-input
            v-model="course.subTitle"
            style="width: 80%; margin-right: 8px"
            placeholder="请输入时段标题"
          />
          <a-icon
            class="dynamic-delete-button"
            type="minus-circle-o"
            @click="removeCourses(course)"
          />
        </a-form-model-item>
        <a-form-model-item
          label="时间范围"
          :prop="'courses.' + index + '.timeFrame'"
        >
          <a-range-picker
            :allowClear="false"
            inputReadOnly
            :placeholder="['开始月份', '结束月份']"
            format="YYYY-MM"
            :value="course.timeFrame"
            :mode="['month', 'month']"
            @panelChange="coursePanelChange"
            @change="courseChange"
            @openChange="pickerOpen(index)"
          >
            <template slot="renderExtraFooter">
              课时{{ currentCourse + 1 }}
            </template>
          </a-range-picker>
        </a-form-model-item>
        <a-divider>课时{{ index + 1 }}<a-icon type="caret-up" /></a-divider>
      </div>
    </a-form-model>

    <div v-if="needAdd">
      <a-button type="primary" @click="addCourses()"> 新增时段 </a-button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "trainingCourse",
  props: {
    trainingId: Number,
    handleType: Number,
    needAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      currentCourse: 0,
      courseForm: {
        courses: [
        ],
      },
    };
  },
  methods: {
    removeCourses(item) {
      let index = this.courseForm.courses.indexOf(item);
      if (index !== -1) {
        this.courseForm.courses.splice(index, 1);
      }
    },
    addCourses() {
      this.courseForm.courses.push({
        id: undefined,
        key: Date.now(),
        subTitle: undefined,
        beginTime: undefined,
        endTime: undefined,
        timeFrame: undefined,
      });
    },

    pickerOpen(index) {
      this.currentCourse = index;
      console.log(index);
    },

    setCourseForm(courseArry) {
      this.courseForm.courses = courseArry;
    },
    coursePanelChange(value, mode) {
      console.log("cpg", value);
      console.log("this.courseForm", this.courseForm);
      this.courseForm.courses[this.currentCourse].timeFrame = value;
      this.courseForm.courses[this.currentCourse].beginTime = moment(value[0])
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      this.courseForm.courses[this.currentCourse].endTime = moment(value[1])
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    courseChange(value) {
      console.log("cg", value);
      this.courseForm.courses[this.currentCourse].timeFrame = value;
      this.courseForm.courses[this.currentCourse].beginTime = moment(value[0])
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      this.courseForm.courses[this.currentCourse].endTime = moment(value[1])
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    reset() {
      this.$refs.courseForm.resetFields();
      this.courseForm.courses = [
        {
          id: undefined,
          key: Date.now(),
          subTitle: undefined,
          beginTime: undefined,
          endTime: undefined,
          timeFrame: undefined,
        },
      ];
    },

    validateForm() {
      let p = new Promise((resolve,reject) => {
        this.$refs.courseForm.validate((valid) => {
          if (valid) {
            resolve(valid);
          } else {
            reject(valid);
          }
        });
      });
      return p;
    },

    getFormInfo() {
      let _this = this;
      let courses = JSON.parse(JSON.stringify(_this.courseForm.courses));
      courses.forEach((ele, index, arr) => {
        arr[index].trainingId = _this.trainingId;
        delete arr[index].key;
        delete arr[index].timeFrame;
      });
      return courses
    },
    courseSubmit() {
      let _this = this;
      _this.$refs.courseForm.validate((valid) => {
        // debugger;
        if (valid) {
          _this.loading = true;
          let courses = JSON.parse(JSON.stringify(_this.courseForm.courses));
          // debugger;
          courses.forEach((ele, index, arr) => {
            arr[index].trainingId = _this.trainingId;
            delete arr[index].key;
            delete arr[index].timeFrame;
          });

          if (_this.handleType === 0) {
            _this.$post("training/course", courses).then((r) => {
              _this.$message.success(r.data.message);
              _this.$emit("handleClose");
              //
              //   this.$emit("success");
              _this.loading = false;
              // this.currentStep++;
              console.log("success", r);
            });
          } else if (_this.handleType === 1) {
            _this.$put("training/course", courses).then((r) => {
              _this.$message.success(r.data.message);
              _this.$emit("handleClose");
              _this.loading = false;
              //   _this.$emit("success");
              console.log("success", r);
            });
          }
        }
      });
    },
  },
};
</script>