<template>
  <a-drawer
    :title="titleText[handleType]"
    :maskClosable="false"
    width="650"
    placement="right"
    @close="handleClose()"
    :visible="drawerShow"
    :closable="false"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <div>
      <a-steps progress-dot :current="currentStep">
        <a-step title="基本信息" />
        <a-step title="课时信息" />
      </a-steps>
    </div>
    <a-divider />
    <div v-if="currentStep === 0">
      <a-form-model
        ref="ruleForm"
        :validateOnRuleChange="true"
        :model="form"
        :rules="formRules"
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="培训名称" prop="title">
          <a-input placeholder="输入培训名称" v-model="form.title" />
        </a-form-model-item>
        <a-form-model-item label="培训形式" prop="trainingMethod">
          <a-select
            mode="default"
            option-label-prop="label"
            :value="form.trainingMethod"
            placeholder="选择培训形式"
            @change="
              (val) => {
                form.trainingMethod = val;
              }
            "
          >
            <a-select-option
              v-for="(r, index) in trainingMethodData"
              :value="r.id"
              :label="r.value"
              :key="index"
              >{{ r.value }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发起部门" prop="deptId">
          <a-tree-select
            allowClear
            v-model="form.deptId"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="deptTreeData"
            placeholder="选择部门"
            @select="selectDept"
          >
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status" v-if="handleType">
          <a-select
            v-model="form.status"
            placeholder="选择是否完成"
          >
            <a-select-option
              v-for="(val, key) in status"
              :value="val.keyy"
              :key="key"
              >{{ val.valuee }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="评估方式" prop="appraiseType">
          <a-select
            allowClear
            mode="default"
            option-label-prop="label"
            :value="form.appraiseType"
            placeholder="选择评估方式"
            @change="
              (val) => {
                form.appraiseType = val;
              }
            "
          >
            <a-select-option
              v-for="(r, index) in appraiseTypeData"
              :value="r.id"
              :key="index"
              :label="r.value"
              >{{ r.value }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="讲师" prop="teacher">
          <a-input placeholder="输入讲师名称" v-model="form.teacher" />
        </a-form-model-item>
        <a-form-model-item label="培训对象" prop="rules">
          <user-rule-creator
            @valueChange="rulesValueChange"
            ref="userRuleCreator"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <!--课时添加-->
    <training-course
      v-if="currentStep === 1"
      ref="course"
      :needAdd="true"
      @handleClose="handleClose()"
      :handleType="handleType"
      :trainingId="Number(form.id)"
    ></training-course>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm
        title="确定放弃编辑？"
        okText="确定"
        cancelText="取消"
        @confirm="handleClose()"
      >
        <a-button style="margin-right: 0.8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="handleClose()" style="margin-right: 0.8rem">取消</a-button>
      <a-button
        type="primary"
        v-if="currentStep === 0"
        @click="handleSubmit"
        :loading="loading"
        >下一步</a-button
      >
      <a-button v-if="currentStep > 0" @click="handlePre">上一步</a-button>
      <a-button
        type="primary"
        v-if="currentStep > 0"
        @click="courseSubmit"
        :loading="loading"
        >{{ handleType === 0 ? "添加" : "编辑" }}</a-button
      >
    </div>
    <!-- 用户组信息查看 -->
    <user-group-info ref="userGroupInfo"> </user-group-info>
  </a-drawer>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import TrainingCourse from "./TrainingCourse.vue";
import UserGroupInfo from "@/views/system/userGroup/UserGroupInfo.vue";
import {getDeptRootId} from '@/utils/tools'
import UserRuleCreator from "../business-common/UserRuleCreator";

const formRules = {
  title: [
    { required: true, message: "请填写培训名称", trigger: "blur" },
    {
      max: 255,
      message: "培训名称长度控制在255以内",
      trigger: "blur",
    },
  ],
  deptId: [{ required: true, message: "请选择部门", trigger: "blur" }],
  trainingMethod: [{ required: true, message: "请选择培训形式", trigger: "blur" }],
  appraiseType: [{ required: true, message: "请选择评估方式", trigger: "blur" }],
  // rules: [{ required: true, message: "请指定规则", trigger: "blur" }],
};
export default {
  name: "trainingOfflineDetail",
  components: { TrainingCourse, UserGroupInfo, UserRuleCreator },
  props: {},
  data() {
    return {
      loading: false,
      titleText: ["新增线下培训", "修改线下培训"],
      handleType: 0,
      drawerShow: false,
      deptTreeData: [],
      trainingMethodData: [],
      appraiseTypeData: [],
      userGroupData: [],
      formRules,
      currentStep: 0,
      baseBak: {},
      coursesBak: {},

      form: {
        id: undefined,
        title: undefined,
        deptId: undefined,
        trainingMethod: undefined,
        appraiseType: undefined,
        status: undefined,
        sourceId: undefined,
        teacher: undefined,
        rules: undefined,
        courses: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.training_status,
      appraiseType: (state) => state.dict.dicts.training_appraise_type,
      trainingMethod: (state) => state.dict.dicts.training_training_method,
    }),
  },
  mounted() {
    this.getDicSelectData();
    this.$get("dept", { rootDeptIds: getDeptRootId() }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
    this.$get("group/list", { groupType: 3 }).then((r) => {
      // debugger;
      this.userGroupData = r.data;
    });
  },
  methods: {
    selectDept(val) {
      this.form.deptId = val;
      this.$refs.ruleForm.validateField("deptId");
    },
    rulesValueChange(s) {
      this.form.rules = s;
    },
    getDicSelectData() {
      let _this = this;
      _this.appraiseTypeData = [];
      _this.trainingMethodData = [];
      _this.appraiseType.forEach((dictType) => {
        let { keyy, valuee } = dictType;
        let typeJson = {};
        typeJson["id"] = Number(keyy);
        typeJson["value"] = valuee;
        _this.appraiseTypeData.push(typeJson);
      });
      _this.trainingMethod.forEach((dictType) => {
        let { keyy, valuee } = dictType;
        let typeJson = {};
        if (Number(keyy) !== 1) {
          typeJson["id"] = Number(keyy);
          typeJson["value"] = valuee;
          _this.trainingMethodData.push(typeJson);
        }
      });
    },

    handlePre() {
      this.currentStep = 0;
      this.$nextTick(()=>{
          if (this.$refs.userRuleCreator)
            this.$refs.userRuleCreator.setRules(this.form.rules);
      })
    },
    setForm(handleType, data) {
      // debugger;
      this.handleType = handleType;
      //编辑
      if (handleType) {
        for (let key in data) {
          this.form[key] = data[key];
        }
        this.$nextTick(()=>{
            if (this.$refs.userRuleCreator)
              this.$refs.userRuleCreator.setRules(this.form.rules);
        })
      } else {
        for (let key in this.form) {
          this.form[key] = undefined;
        }
        this.currentStep = 0;
        this.$nextTick(()=>{
            if (this.$refs.userRuleCreator)
              this.$refs.userRuleCreator.setRules(null);
        })
      }
      this.drawerShow = true;
    },
    //下一步
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          //新增，下一步
          if (this.handleType === 0) {
            this.loading = false;
            this.currentStep++;
          } else if (this.handleType === 1) {
            this.loading = false;
            this.currentStep++;

            this.$nextTick(() => {
              let courses = JSON.parse(JSON.stringify(this.form.courses));
              if (courses && courses.length > 0) {
                let arry = [];
                courses.forEach((item, index, arr) => {
                  let json = {};
                  json.id = item.id;
                  json.key = index;
                  json.subTitle = item.subTitle;
                  json.beginTime = item.beginTime;
                  json.endTime = item.endTime;
                  json.timeFrame = [
                    moment(item.beginTime, "YYYY-MM"),
                    moment(item.endTime, "YYYY-MM"),
                  ];
                  arry.push(json);
                });
                this.$refs.course.setCourseForm(arry);
              }
            });
          }
        }
      });
    },
    handleClose() {
      this.drawerShow = false;
      this.loading = false;
      this.reset();
    },
    reset() {
      // debugger;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
      if (this.$refs.course) {
        this.$refs.course.reset();
      }

      for (let key in this.form) {
        this.form[key] = undefined;
      }

      this.currentStep = 0;
    },

    //课时相关
    // courseSubmit() {
    //   this.$refs.course.courseSubmit();
    // },

    //课时填完后处理
    courseSubmit() {
      this.$refs.course
        .validateForm()
        .then((v) => {
          console.log(v);
          this.loading = true;
          let courses = this.$refs.course.getFormInfo();
          this.form.courses = courses;
          let trainingDetail = JSON.parse(JSON.stringify(this.form));
          if (this.handleType === 0) {
            this.$post("training/detail", { ...trainingDetail }).then((r) => {
              this.$message.success(r.data.message);
              this.handleClose();
              this.$emit("success");
              console.log(r);
            });
          } else if (this.handleType === 1) {
            this.$put("training/detail", { ...trainingDetail }).then((r) => {
              this.$message.success(r.data.message);
              this.handleClose();
              this.$emit("success");
              console.log(r);
            });
          }
          console.log(this.form);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
