<template>
  <a-modal
    v-if="groupData"
    v-model="groupInfoVisiable"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="800"
    title="用户组信息"
  >
    <a-layout class="user-group-info">
      <a-layout-content class="user-group-content-one">
        <p>
          <my-icon type="name" />&nbsp;&nbsp;用户组名称：{{
            groupData.groupName
          }}
        </p>
        <p>
          <my-icon type="type" />&nbsp;&nbsp;用户组类型：{{
            groupData.groupType | groupTypeStr
          }}
        </p>
      </a-layout-content>
      <a-layout-content class="user-group-content-two">
        <p>
          <my-icon type="time" />&nbsp;&nbsp;创建时间：{{
            groupData.createTime
          }}
        </p>
        <p>
          <my-icon type="time" />&nbsp;&nbsp;修改时间：{{
            groupData.updateTime
          }}
        </p>
      </a-layout-content>
    </a-layout>
    <a-layout v-if="entities" class="user-group-info">
      <a-layout-content class="user-group-content-four">
        <div><my-icon type="hear0t" />详情：</div>
        <div style="margin-top: 10px">
          <a-tag style="margin: 0 5px 5px"
            v-for="(item, index) of userList"
            :key="index"
          >
            {{ item.username }}
          </a-tag>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-if="groupData.description" class="user-group-info" style="margin-top: 10px">
      <a-layout-content class="user-group-content-four">
        <span><my-icon type="desc" />用户组描述：</span><br />
        <span class="info" :title="groupData.description">{{
          groupData.description
        }}</span>
      </a-layout-content>
    </a-layout>
  </a-modal>
</template>
<script>

export default {
  name: "UserGroupInfo",
  data() {
    return {
      groupInfoVisiable: false,
      groupData: null,
      loading: true,
      entities: null,
    };
  },
  mounted() {},
  methods: {
    setData(data) {
      this.groupInfoVisiable = true;
      this.groupData = data;
      this.loadRuleData(data.rules);
    },
    loadRuleData(rules) {
      this.loading = true;
      this.$get(`group/convert-rules/${rules}`).then((resp) => {
        this.loading = false;
        this.entities = resp.data.entities;
      });
    },
  },
  computed: {
    userList() {
      let map = {};
      if (this.entities) {
        for (let item of this.entities) {
          let list = item.userList;
          let operation = item.operation;

          for (let u of list) {
            if (operation == "+") map[u.userId] = u;
            else delete map[u.userId];
          }
        }
      }
      return Object.values(map);
    },
  },
  filters: {
    groupTypeStr(v) {
      if (v == 1) {
        return "社群群组";
      }
      if (v == 2) {
        return "工程师群组";
      }
      if (v == 3) {
        return "培训组";
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "UserGroupInfo";
</style>
